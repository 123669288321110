import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  { path: "/", redirect: "/buyers/todaySpecial" },
  {
    path: '/',
    name: 'Home',
    component: Home,
   
    children: [
      {
        path: '/login',
        name: 'login',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/login.vue')
        }
      },
      {
        path: '/regist',
        name: 'regist',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/regist.vue')
        }
      },
      {
        path: '/forgotPassword',
        name: 'forgotPassword',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/forgotPassword.vue')
        }
      },
      {
        path: '/pdf',
        name: 'pdf',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/pdf.vue')
        }
      },
      {
        path: '/protocol',
        name: 'protocol',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/protocol.vue')
        }
      },
      {
        path: '/supplier',
        name: 'supplier',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/supplier.vue')
        }
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/aboutUs.vue')
        }
      },
      // { path: "/buyers", redirect: "/buyers/shoppingCart" },
      {
        path: '/buyers',
        name: 'buyers',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/buyers/buyers.vue')
        },
        children: [
          {
            path: 'shoppingCart',
            name: 'shoppingCart',
            component: function () {
              return import(/* webpackChunkName: "about" */ '../views/buyers/shoppingCart.vue')
            }
          },
          {
            path: 'purchasedOrderList',
            name: 'purchasedOrderList',
            component: function () {
              return import(/* webpackChunkName: "about" */ '../views/buyers/purchased/purchasedOrderList.vue')
            }
          },
          {
            path: 'purchasedDetail',
            name: 'purchasedDetail',
            component: function () {
              return import(/* webpackChunkName: "about" */ '../views/buyers/purchased/purchasedDetail.vue')
            }
          },
         
          {
            path: 'shoppingCenter',
            name: 'shoppingCenter',
            component: function () {
              return import(/* webpackChunkName: "about" */ '../views/buyers/shoppingCenter.vue')
            }
          },
          {
            path: 'todaySpecial',
            name: 'todaySpecial',
            component: function () {
              return import(/* webpackChunkName: "about" */ '../views/buyers/todaySpecial.vue')
            }
          },
           {
                path: 'pendingPaymentList',
                name: 'pendingPaymentList',
                component: function () {
                  return import(/* webpackChunkName: "about" */ '../views/buyers/pendingPay/pendingPaymentList.vue')
                }
              },
              {
                path: 'pendingDetail',
                name: 'pendingDetail',
                component: function () {
                  return import(/* webpackChunkName: "about" */ '../views/buyers/pendingPay/pendingDetail.vue')
                }
              },
      

          {
            path: 'securitySettings',
            name: 'securitySettings',
            component: function () {
              return import(/* webpackChunkName: "about" */ '../views/buyers/securitySettings.vue')
            }
          },
          {
            path: 'dataEditing',
            name: 'dataEditing',
            component: function () {
              return import(/* webpackChunkName: "about" */ '../views/buyers/dataEditing.vue')
            }
          },
          {
            path: 'accountBalance',
            name: 'accountBalance',
            component: function () {
              return import(/* webpackChunkName: "about" */ '../views/buyers/accountBalance/accountBalance.vue')
            }
          },
          {
            path: 'financialDetails',
            name: 'financialDetails',
            component: function () {
              return import(/* webpackChunkName: "about" */ '../views/buyers/accountBalance/financialDetails.vue')
            }
          }
        ]
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
