import zhlocale from 'element-ui/lib/locale/lang/zh-CN'
    const zh_cn = {
        ...zhlocale,
        "home":{
            "dqyy":"当前语言",
            "zw":"中文",
            "qh":"切换",
            "cgs":"采购商",
            "gys":"供应商",
            "gywm":"关于我们",
            "tc":"退出",
            "hyn":"欢迎您",
            "lxwm":"联系我们",
            "swhz":"商务合作",
            "rczp":"人才招聘",
            "yhxy":"用户协议",
            "yqlj":"友情链接",
            "qdl":"请登录",
            "cgszx":"采购商中心",
            "ydl":"已登录",
            "qjrcgzx":"请进入采购中心"

        },
        "login":{
            "qdl":"请登录",
            "hydl":"欢迎登录",
            "qsryxhsj":"请输入邮箱或手机号",
            "qsrmm":"请输入密码",
            "wjmm":"忘记密码",
            "ljdl":"立即登录",
            "cjxzh":"创建新账户",
            "wmdfw":"我们的服务",
            "wmdcp":"我们的产品",
            "wmdsl":"我们的实力",
            "wmdys":"我们的优势",
            "gfzpbz":"官方正品保障",
            "gfzpbzxq":"全球数字代码和国内外众多知名厂商接轨,",
            "ygbz":"严格保障产品来源和质量.",
            "zxfhgfb":"在线发货更方便",
            "zxfhgfbxq":"数字卡密，24小时在线自动发货，方便快捷。",
            "ptzc":"平台亦支持API提货，减少人工操作。",
            "gmslbxz":"购买数量不限制",
            "gmslbxzxq":"按需采购，无需大量压货，减少库存压力,",
            "zjyz":"使您的流动 资金运转更灵活。",
            "qwzdj":"全网最低价格",
            "qwzdjg":"批量购买享受折扣优惠，物美价优。",
            "zkrm":"平台不定期会推出折扣特卖，返点激励机制。",
            "zykf":"专业客服高品质服务",
            "zykfxq":"专业客服提供一对一客户服务，",
            "jdyh":"及时解答您的任何疑惑。",
            "zffsdy":"支付方式多样",
            "zffsdyxq":"平台支持支付宝，微信，银行卡（银联），信用卡（银联），",
            "yhzz":"银行转账，Payal等灵活满足全球范围,客户的支付需求。",
            "yhsl":"用户数量",
            "gyssl":"供应商数量",
            "ycjdd":"月成交订单",
            "dlcg":"登录成功"

        },
        "regist":{
            "sjzc":"手机注册",
            "yxzc":"邮箱注册",
            "qwcyxzc":"请完成第一步邮箱注册",
            "yzsjh":"验证手机号",
            "yzyx":"验证邮箱",
            "szyhxx":"设置用户信息",
            "wczc":"完成注册",
            "qxz":"请选择",
            "qsrsjh":"请输入手机号",
            "qsryx":"请输入邮箱",
            "yzm":"验证码",
            "qsryzm":"请输入验证码",
            "hqyzm":"获取验证码",
            "hhq":"后获取",
            "xyb":"下一步",
            "yhm":"用户名",
            "qsryhm":"请输入用户名",
            "wz":"网站",
            "gsmc":"公司名称",
            "qsrgs":"请输入正确的公司名称",
            "zch":"注册号或税号",
            "qsrzch":"请输入正确的注册号或税号",
            "gsdz":"公司地址",
            "qsrxxdz":"请输入详细地址",
            "mm":"密码",
            "qsrzhmim":"请输入8-20位字母/数字/英文符号组合密码",
            "zcmim":"再次输入密码",
            "zcsrmm":"请再次输入密码",
            "yydty":"已阅读并同意",
            "hztk":"合作条款和隐私政策",
            "khsq":"提交开户申请",
            "ndzcsqytj":"您的注册申请已经提交，请等待审核部门审核！",
            "jjcl":"如需加急处理，请联系管理员",
            "qd":"确定",
            "qsrwz":"请输入网站",
            "xzgsdz":"选择公司地址",
            "xzgsxxdz":"选择公司详细地址",
            "qsrmm":"请输入密码",
            "yxgsbzq":"邮箱格式不正确",
            "mmgsbzq":"密码格式不正确",
            "lcmmbyz":"两次密码输入不一致",
            "zchgsbzq":"注册号或税号格式不正确,请重新输入",
            "yz":"请输入邮政编码",
            "cs":"请输入城市",
            "gj":"请输入国家",
            "szxmm":"设置新密码",
            "tjxg":"提交修改",
            "qdl":"去登录"
            

        },
        "supplier":{
            "qmfg":"全面覆盖中国大陆、港澳台、东南亚及欧美市",
            "ptcg":"平台批量采购",
            "plcg":"CodesFlying WorldWide Digital Game批量采购，集中上架平台进行销售及库存管理。",
            "gh":"支持API供货",
            "xsqk":"通过API对接，减少人工操作时间，实时掌握销售情况。",
            "zydj":" 优质分销资源对接",
            "ytg":" 在线云托管",
            "jmjs":"区块链安全加密技术",
            "sctg":"本土化市场推广",
            "zgdl":"加入CodesFlying WorldWide Digital Game，迅速与中国大陆及海外市场接轨，让更多的优质零售商分销您的产品加入CodesFlying WorldWide Digital Game，迅速与中国大陆及海外市场接轨，让更多的优质零售商分销您的产品",
            "xsbb":"您可以批量上传游戏产品至发行中心，在线管理销售动向、库存情况，自动生成销售报表",
            "szhfz":"支持区块链安全加密技术，对您的产品进行数字化封装、存储，实时监管产品去向、状态，保障卡密安全",
            "jjzg":"聚集中国、海外营销资源，本土化营销推广您的产品，增加市场曝光度及产品销量，提升品牌影响力"
        },
        "about":{
           "jc":"CODES FLYING数字游戏（以下简称“ CodesFlying”）成立于2010年，起源于中国。目前在中国香港，英国和东南亚设有分支机构。我们是销售Steam，Origin，Uplay，视频游戏和其他平台CD-Key的在线市场，在过去的几年中，我们赢得了数以万计的客户的信任。 所有这些归功于我们为客户提供最优惠的价格，专业的服务和快速的自动交付系统。 我们最大的优势是我们对客户的友好态度，快速的业务流程和优惠的价格。同时，CODES FLYING整合了行业资源，并通过多种营销方式提高了游戏产品的知名度和品牌影响力。现在，我们主要是全球计算机和游戏机的批发商。今天，CODES FLYING被公认为游戏行业的领先和可信赖的合作伙伴。将来，我们将致力于与更多优秀的国内外游戏开发商/发行商寻求合作，提供一站式营销和数字发行服务，并为合作伙伴带来更多高质量的作品。我们希望对于那些也致力于游戏的人来说，我们可以成为他们有用的业务合作伙伴，因此，如果您有兴趣，我们将很乐意与您开展互利合作。我们的专长：-B2B和B2C电子商务，国际贸易。-电脑/游戏机游戏盒。-游戏周边产品。（游戏机配件，美术书籍等）-PC的数字游戏代码（Steam，Origin，Uplay等）-数字Xbox游戏代码，数字Xbox实时代码。-游戏预付卡（STEAM，PSN，Nintendo，Xbox等）",
           "zb":" 总部",
           "mc":"名称",
           "dz":"地址",
           "dh":"电话",
           "yx":"邮箱",
           "hw":"海外",
           "khzc":" 客户支持",
           "kfyx":"客服邮箱",
           "kfdh":"客服电话",
           "jdzh":"我们很乐意回答您的帐户使用情况，产品咨询，技术支持和其他问题。",
           "swkf":"商务开发",
           "lxwm":"如果您对我们的业务感兴趣，欢迎与我们联系。"
        },
        "buyers":{
            "gwc":"购物车",
            "jrtj":"今日特价",
            "xgzx":"选购中心",
            "ddzx":"订单中心",
            "dfk":"待付款",
            "ygm":"已购买",
            "cwgl":"财务管理",
            "zhye":"账户余额",
            "xxgl":"信息管理",
            "zlbj":"资料编辑",
            "aqsz":"安全设置",
            "zjmx": "资金明细"
            

        },
        "shoppingCar":{
            "zhye":"账户余额",
            "pt":"平台",
            "dq":" 地区",
            "yy":"语言",
            "lx":"类型",
            "slsx":"总数",
            "jg":"价格",
            "sl":"数量",
            "zj":"总价",
            "gwck":"您的购物车空空如也",
            "zwsjkcz":"暂无数据可操作",
            "qgxsp":"请勾选商品",
            "sysErr": "系统错误",
            "yebz": "余额不足，请先充值",
            "kcbz": "库存不足",
            "dykc": "购买数量不能大于库存",
            "xyzdqp": "购买数量不能小于最低起拍数量"

        },
        "today":{
            "jrthzq":"今日特惠专区",
            "qsryxmc":"请输入游戏名称",
            "sypt":"所有平台",
            "syyy":"所有语言",
            "sydq":"所有地区",
            "ss":"搜索",
            "zdpf":"最低批发数量",
            "xzzgwc":" 新增至购物车",
            "gm":" 购买",
            "zwsj":"暂无数据",
            "qsrysj":"请输入要搜索的信息",
            "qxzsl":"请选择数量",
            "thgwccg":"添加购物车成功"


        },
        "pending":{
            "cgdd":"采购订单",
            "dfkdd":"待付款订单",
            "ddbh":"订单编号",
            "spzs":"商品总数",
            "qqrbzf":"请确认并支付",
            "dgsj":"订购时间",
            "je":"金额",
            "ddfk":"等待付款",
            "qqrnddd":"请确认您的订单信息",
            "wmy":"我们已于",
            "ddsq":"收到您的订单申请，您的订单为",
            "jc":"请检查您的订单是否正确，并及时完成付款。谢谢",
            "ddxq":"订单详情",
            "ddsj":"订单时间",
            "xdsj":"下单时间",
            "zfdd":"支付订单",
            "nqd":"您确定要为订单付款吗?",
            "sfzf":"是否支付订单?",
            "qx":"取 消",
            "tjdd":"提交订单",
            "zfcg":"支付成功",
            "ygm":"已购买订单",
            "qsrddbh":"请输入订单编号",
            "qqrbxz":"请确认并及时下载!",
            "xz":"下载",
            "wjyxz":"文件已经被下载",
            "qsrspmc":"请输入商品名称",
            "ci":"次",
            "spm":"商品名",
            "jhm":"激活码",
            "lqsj":"领取时间",
            "spmx":"商品明细",
            "gxgm":"感谢您购买我们的产品.这是您的产品清单,请即使下载",
            "yl":"预览PDF内容",
            "nian":"年",
            "yue":"月",
            "ri":"日",
            "ksrq":"开始日期",
            "jsrq":"结束日期",
            "qxdd":"取消订单",
            "fhdd":"返回列表",
            "pfj":"批发价",
            "qux":"取 消",
            "fxs":"发行商",
            "sl":"数量"

            
        },
        "account":{
            "hbdw":"货币单位",
            "ckmx":"查看明细",
            "zhcz":"账户充值",
            "czqlx":"充值请联系",
            "wx":"微信",
            "cwmx":"财务明细",
            "jylx":"交易类型",
            "cz":"储值",
            "gw":"购物",
            "jyrq":"交易日期",
            "czuo":"操作",
            "ck":"查看"


        },
        "dataEdit":{
            "sjbj":"资料编辑",
            "xydm":"统一公司注册码",
            "qsrxydm":"请输入统一公司注册码",
            "qsrzq":"请输入正确的统一公司注册码",
            "bjcg":"编辑成功"

        },
        "setting":{
            "xgmm":"修改密码",
            "ymm":"原密码",
            "qsrymm":"请输入原密码",
            "xmm":"新密码",
            "qsrxmm":"请输入新密码",
            "qdl": '返回首页登录',
            "zcmm":"再次确认密码",
            "qsrzxmm":"请再次确认密码",
            "bdsj":"绑定手机",
            "xsj":"新手机",
            "bdyx":"绑定邮箱",
            "xyx":"新邮箱",
            "ghsj":"更换绑定手机",
            "ysj":"原手机号",
            "ghyx":"更换绑定邮箱",
            "yyx":"原邮箱",
            "xgcg":"修改成功",
            "bdcg":"绑定手机号成功~",
            "bdyxcg":"绑定邮箱成功~"
            


        },
        "pdf": {
            "download": '确定下载PDF',
            "back": "返回上一层"
        }

        
    }


    export default zh_cn