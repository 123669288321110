import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Distpicker from 'v-distpicker'
import axios from 'axios'
import store from './store'
import i18n from './util/i18n'




Vue.config.productionTip = false
Vue.use(ElementUI);

 


Vue.prototype.$http = axios
if (process.env.NODE_ENV == 'development') {
  // Vue.prototype.$url = 'http://192.168.0.8:7001'
  Vue.prototype.$url = 'https://api.web.codes-flying.com'
} else {
  Vue.prototype.$url = 'https://api.web.codes-flying.com'
  // Vue.prototype.$url = 'http://192.168.1.119:7004'
}

const httpRequest = options => { //options 传递过来的参数 {method: '', data: {} || params: {}, url: ''}
    return new Promise((resolve, reject) => {
      let cfg = {
        url: options.url,
        method: options.method,
        headers: {
          'token': sessionStorage.getItem('token') != null ? sessionStorage.getItem('token') : ''
        }
      };
      if (options.method === 'get' || options.method === 'GET') { //判断请求方式
   
        cfg.params = options.params;
      } else {
        cfg.data = options.data;
      }
      const res = axios(cfg);
      try {
        resolve(res)
      } catch (e) {
        reject(e)
      }
    })
  };

  Vue.prototype.$http = async function request(cfg) {
    let data = await httpRequest(cfg)
    if (data.data.code === 401) {
      this.$message.error(data.data.msg)
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('user')
      this.$store.commit('setToken','')
      this.$store.commit('setUser','')
      this.$store.commit('setUserName','')
      router.push('/login')
      this.$message.error(this.$t('login.qdl'))   
    } else if (data.data.code === 402) { //无权限
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('user')
      this.$store.commit('setToken','')
      this.$store.commit('setUser','')
      this.$store.commit('setUserName','')
      router.push('/login')
      this.$message.error(this.$t('login.qdl'))
      return 
    } else {
      return data
    }
  }

  router.beforeEach((to,from,next) => {
    document.title = 'Codes Flying Digital Game / 代码飞舞游戏数字商店'
    let token = sessionStorage.getItem('token'),
        whiteName = ['/login','/supplier','/aboutUs','/regist','/forgotPassword']
    if(!token && !whiteName.includes(to.path)){
      router.push('/login')
    }
    next()
  })
Vue.component('v-distpicker', Distpicker)
new Vue({
  router,
  store ,
  i18n, 
  render: function (h) { return h(App) }
}).$mount('#app')
