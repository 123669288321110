<template>
  <div id="app">
    <div id="nav">
      <router-link to="/"></router-link>
    </div>
    <router-view/>
  </div>
</template>
<script>
export default {
  data(){
    return{
    }
  }
}
</script>
<style lang="less">
body{
  margin:0;
  padding:0;
  background: #f5f5f5;
}
._box{
  background: #fff;
  width: 100% !important;
  border-radius: 10px;
  margin-bottom: 80px;
}
.noData{
    text-align: center;font-size: 14px;
    font-family: PingFang;
    font-weight: bold;
    color: #333333;;
    display: flex;
    align-items: center;
    justify-content: center;margin-top:80px;
  }
  .download_tips{
    font-size: 12px;
font-family: PingFang;
font-weight: 500;
color:#999;
  }


</style>
