import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'
import zh_cn from '../i18n/zh'
import en_us from '../i18n/en'
Vue.use(VueI18n)
 

// 以下为语言包单独设置的场景，单独设置时语言包需单独引入
const messages = {
  zh_cn,   // 中文语言包
  en_us    // 英文语言包
}

 
// 最后 export default，这一步肯定要写的。
const i18n = new VueI18n({
 // set locale 默认显示中文
  locale : (function() {
    if (localStorage.getItem('lang')) {
      return localStorage.getItem('lang')
    } else {
      return 'en_us'
    }
  })(),
  
  messages : messages // set locale messages
})
locale.i18n((key, value) => i18n.t(key, value)) //重点：为了实现element插件的多语言切换
export default i18n