import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
const state = {//要设置的全局访问的state对象
    // showFooter: true,
    // changableNum: 0
    //要设置的初始属性值
    token:sessionStorage.getItem('token') || '',
    user: JSON.parse(sessionStorage.getItem('user')) || {},
    email: sessionStorage.getItem('email') || '',
    phone: sessionStorage.getItem('phone') ||  '',
    username: sessionStorage.getItem('username') || '',
    accountBalance: sessionStorage.getItem('accountBalance') || '',
    carNum: sessionStorage.getItem('carNum') || 0,
    phoneArea:localStorage.getItem('phoneArea') || '86'
    
};
const getters = {   //实时监听state值的变化(最新状态)
      //承载变化的showFooter的值
    getterToken(state){
        return state.token
    },
    gettrerUser(state){
        return state.user
    },
    gettrerusername(state){
        return state.username
    },

   
};
const mutations = {
    setToken(state,value){
        state.token = value ||''
    },
    setUser(state,value){
        state.user = value ||''
    },
    setEmail(state,value){
        state.email = value ||''
    },
    setPhone(state,value){
        state.phone = value ||''
    },
    setUserName(state,value){
        state.username = value ||''
    },
    setAccountBalance(state,value){
        state.accountBalance = value ||''
    },
    setcarNum(state,value){
        state.carNum = value ||''
    },
    setPhoneArea(state,value){
        state.phoneArea = value ||'86'
    },


    
};
const actions = {
    tokenAction(context,value){
        context.commit('setToken',value)
    },
    tokenUser(context,value){
        context.commit('setUser',value)
    },

  
};
const store = new Vuex.Store({
    state,
    getters,
    mutations,
    actions
});
export default store;