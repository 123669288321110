<template>
  <div class="home">
    <div class="header">
      <div class="header_box">
        <div class="header_box_left">
          <span @click="login"><img class="logoimg" src="../assets/logo.png" /></span>
        </div>
        <div class="header_box_right">
          <div class="right_box">
            <router-link to="/login" tag="div">{{
              $t("home.cgs")
            }}</router-link>
            <router-link to="/supplier" tag="div">{{
              $t("home.gys")
            }}</router-link>
            <router-link to="/aboutUs" tag="div">{{
              $t("home.gywm")
            }}</router-link>
          </div>
          <!-- 未登录状态 -->
          <div class="login_text" v-if="!$store.state.token" @click="login">
            {{ $t("home.hyn") }}，<span class="login" style="text-decoration: underline;cursor: pointer;">{{
              $t("home.qdl")
            }}</span>
          </div>
          <!-- 登录状态 -->
          <div class="login_text" v-if="$store.state.token">
            {{ $t("home.hyn")
            }}<span class="login" style="margin:0 10px;">{{ $store.state.username }}</span
            >
            <span style="text-decoration: underline;cursor: pointer;" @click="tobuyers">{{$t('home.cgszx')}}</span><span class="login login_out" @click="loginOut">{{
              $t("home.tc")
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="homeContent">
      <router-view />
    </div>
    <div class="home_bottom_box">
      <div class="home_bottom">
        <div class="home_bottom_left">
          <div class="about_us">
            <span @click="jumper('/aboutUs')">{{ $t("home.gywm") }}</span>
            <span @click="jumper('/aboutUs')"> {{ $t("home.lxwm") }}</span>
            <span @click="jumper('/aboutUs')"> {{ $t("home.swhz") }}</span>
            <span @click="jumper('/aboutUs')">{{ $t("home.rczp") }}</span>
            <span @click="torgist">{{ $t("home.yhxy") }}</span>
          </div>
          <div class="link_box">
            <span>{{ $t("home.yqlj") }}：</span
            ><span class="item_link" ><a href="https://www.codes-flying.com/#/login" target="_blank">代码飞舞数字游戏商店</a></span
            >
          </div>
          <div>版权所属CodesFlying WorldWide Digital Game</div>
        </div>
        <div class="home_bottom_right" v-if="langType === 'en_us'" @click="langToggle(1)">
          {{ $t("home.dqyy") }}：En<span >[Switch]</span>
        </div>
        <div class="home_bottom_right" v-if="langType === 'zh_cn'" @click="langToggle(2)">
          {{ $t("home.dqyy") }}：中文简体<span 
            >[切换]</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      isShow: true,
      langType: 'en_us'
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
    }),
  },
  created() {
    let langType = localStorage.getItem('lang')
    if(langType){
      this.langType = langType
    }
    
  },
  methods: {
    torgist(){
      this.$router.push({
        path:'/regist',
        query:{
          userDialog:true,
         
        }
      })
    },
    jumper(e){
      this.$router.push(e);
    },
    tobuyers(){
      this.$router.push("/buyers/todaySpecial");
    },
    login() {
      this.$router.push("/login");
    },
    langToggle(e) {
      switch(e){
        case 1:
          localStorage.setItem('lang','zh_cn')
          this.langType = "zh_cn"
          this.$i18n.locale = "zh_cn";
          break;
        case 2:
          localStorage.setItem('lang','en_us')
          this.langType = "en_us"
          this.$i18n.locale = "en_us";
          break;
      }
    },
    //退出登录
    async loginOut() {
      let cfg = {
        method: "POST",
        url: this.$url + "/api/logout",
        data: {},
      };
      let res = await this.$http(cfg);
      if (res.data.code === 0) {
        this.$message.error("退出成功");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("phone");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("accountBalance");
        sessionStorage.removeItem("carNum");
        this.$store.commit("setToken", "");
        this.$store.commit("setUser", "");
        this.$store.commit("setEmail", "");
        this.$store.commit("setPhone", "");
        this.$store.commit("setUserName", "");
        this.$router.push("/login");
      } else {
        this.$message.error(res.data.msg);
      }
    },
  },
};
</script>
<style scoped lang="less">
a{
  color:#fff;
  text-decoration: none;
}
.router-link-active {
  border-bottom: 2px solid #fff;
  cursor: pointer;
}
.home {
  min-width: 1280px;
  height: 100vh;
  background: #f5f5f5;
  .header {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    z-index: 999;
    background: #4d89f7;
    .header_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      margin: 0 auto;
      .header_box_left {
        display: flex;
        align-items: center;
        .logoimg {
          height: 60px;
          object-fit: cover;
          margin-right: 10px;
        }
      }
      .header_box_right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 50%;
        color: #fff;
        .right_box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 50%;
          div {
            font-size: 14px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #ffffff;
            line-height: 30px;
            box-sizing: border-box;
            cursor: pointer;
          }
          .active {
            border-bottom: 2px solid #fff;
          }
        }
        .login_text {
          font-size: 14px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #ffffff;
          line-height: 30px;
          .login {
            // text-decoration: underline;
          }
          .login_out {
            text-decoration: none;
            margin-left: 10px;
            cursor: pointer;
            // text-decoration: underline;
          }
        }
      }
    }
  }
  .homeContent {
    position: relative;
    display: flex;
    padding-top: 70px;
    // width: 1280px;
    // min-height: calc(100vh - 170px) ;
    margin:0 auto;
    padding-bottom: 40px;
    
    // background: #f5f5f6;
  }
  .home_bottom_box {
    position: fixed;
    bottom: 0;
    left:0;
    width: 100%;
    z-index:999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #373f5e;
    height: 40px;
    font-size: 10px;
    font-family: PingFang;
    font-weight: 500;
    color: #ffffff;
    line-height: 30px;
    .home_bottom {
      width: 80%;
      min-width: 1280px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .home_bottom_left {
        display: flex;
        align-items: center;
        width: 80%;
        div {
          line-height: 25px;
        }
        .about_us,
        .link_box {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        .about_us span {
          margin-right: 20px;
          cursor: pointer;
        }
        .item_link {
          display: flex;
          align-items: center;
          margin-right: 15px;
          .line {
            width: 1px;
            height: 12px;
            margin-left: 5px;
            background: #fff;
          }
        }
      }
      .home_bottom_right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
        cursor: pointer;
      }
    }
  }
}
</style>
