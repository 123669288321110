import enlocale from 'element-ui/lib/locale/lang/en'
 const en_us =  {
        ...enlocale,
        "home":{
            "dqyy":"Current language",
            "zw":"Chinese",
            "qh":"Switch",
            "cgs":"Buyers",
            "gys":"Supplier",
            "gywm":"About Us",
            "tc":"Drop out",
            "hyn":"Welcome",
            "qdl":"please sign in",
            "lxwm":"contact us",
            "swhz":"Business Cooperation",
            "rczp":"Talent Recruitment",
            "yhxy":"User Agreement",
            "yqlj":"Links",
            "cgszx":"Buyer Center",
            "ydl":"Has logged",
            "qjrcgzx":"Please enter the purchasing center"


            

        },
        "login":{
            "qdl":"please sign in",
            "hydl":"Login please",
            "qsryxhsj":"Please enter email or phone number",
            "qsrmm":"Please enter the password",
            "wjmm":"Forgot password",
            "ljdl":"log in immediately",
            "cjxzh":"Create a new account",
            "wmdfw":"our service",
            "wmdcp":"our products",
            "wmdsl":"Our strength",
            "wmdys":"Our advantage",
            "gfzpbz":"Official genuine guarantee",
            "gfzpbzxq":"The global digital code is in line with many well-known manufacturers at home and abroad,",
            "ygbz":"Strictly guarantee product source and quality.",
            "zxfhgfb":"Online delivery is more convenient",
            "zxfhgfbxq":"Digital card secret, 24 hours online automatic delivery, convenient and fast。",
            "ptzc":"The platform also supports API delivery, reducing manual operations.",
            "gmslbxz":"Unlimited purchase quantity",
            "gmslbxzxq":"Purchasing on-demand, no need to press large quantities of goods, reducing inventory pressure,",
            "zjyz":"Make your liquidity more flexible。",
            "qwzdj":"Lowest price on the whole network",
            "qwzdjg":"Buy in bulk and enjoy discounts, good quality and good price.",
            "zkrm":"From time to time, the platform will launch special discounts and rebate incentives.",
            "zykf":"Professional customer service, high-quality service",
            "zykfxq":"Professional customer service provides one-to-one customer service,",
            "jdyh":"Answer any questions you have in a timely manner.",
            "zffsdy":"Various payment methods",
            "zffsdyxq":"The platform supports Alipay, WeChat, bank card (UnionPay), credit card (UnionPay)",
            "yhzz":"Bank transfer, Payal, etc. flexibly meet the payment needs of customers worldwide.",
            "yhsl":"amount of users",
            "gyssl":"Number of suppliers",
            "ycjdd":"Monthly transaction order",
            "dlcg":"login successful"
        },
        "regist":{
            "sjzc":"Register your phone",
            "yxzc":"Email registration",
            "qwcyxzc":"Please complete the first step email registration",
            "yzsjh":"Phone number",
            "yzyx":"Verify email",
            "szyhxx":"Set user information",
            "wczc":"Complete registration",
            "qxz":"please choose",
            "qsrsjh":"Please enter phone number",
            "qsryx":"Please input your email",
            "yzm":"Verification code",
            "qsryzm":"please enter verification code",
            "hqyzm":"get verification code",
            "hhq":"Get later",
            "xyb":"Next step",
            "yhm":"username",
            "qsryhm":"please enter user name",
            "wz":"website",
            "gsmc":"Company Name",
            "qsrgs":"Please enter the correct company name",
            "zch":"Registration number or tax number",
            "qsrzch":"Please enter the correct registration number or tax number",
            "gsdz":"company address",
            "qsrxxdz":"Please enter the detailed address",
            "mm":"password",
            "qsrzhmim":"Please enter 8-20 letter/number/English symbol password",
            "zcmim":"Enter the password again",
            "zcsrmm":"Please enter the password again",
            "yydty":"Have read and agree",
            "hztk":"Terms of cooperation and privacy policy",
            "khsq":"submit application",
            "ndzcsqytj":"Your registration application has been submitted, please wait for the review department to review!",
            "jjcl":"For urgent processing, please contact the administrator",
            "qd":"determine",
            "qsrwz":"Please enter the website",
            "xzgsdz":"Choose company address",
            "xzgsxxdz":"Select company address",
            "qsrmm":"Please enter the password",
            "yxgsbzq":"E-mail format is incorrect",
            "mmgsbzq":"Incorrect password format",
            "lcmmbyz":"Two password entries are inconsistent",
            "zchgsbzq":"The registration number or tax number format is incorrect, please re-enter",
            "yz":"Please enter the postal code",
            "cs":"Please enter the city",
            "gj":"Please enter country",
            "szxmm":"Set new password",
            "tjxg":"Submit changes",
            "qdl":"Go to log in"
        },
        "supplier":{
            "qmfg":"Comprehensive coverage of mainland China, Hong Kong, Macau, Taiwan, Southeast Asia, Europe and the United States",
            "ptcg":"Platform bulk purchase",
            "plcg":"CodesFlying WorldWide Digital Game bulk purchases, centralized on the platform for sales and inventory management.",
            "gh":"Support API supply",
            "xsqk":"Through API docking, reduce manual operation time and grasp the sales situation in real time.",
            "zydj":" High-quality distribution resources docking",
            "ytg":" Online cloud hosting",
            "jmjs":"Blockchain security encryption technology",
            "sctg":"Localized marketing",
            "zgdl":"Join CodesFlying WorldWide Digital Game to quickly connect with mainland China and overseas markets, allowing more high-quality retailers to distribute your products. Join CodesFlying WorldWide Digital Game to quickly connect with mainland China and overseas markets, allowing more high-quality retailers to distribute your products",
            "xsbb":"You can upload game products in batches to the distribution center, manage sales trends and inventory online, and automatically generate sales reports",
            "szhfz":"Support blockchain security encryption technology, digitally encapsulate and store your products, monitor the whereabouts and status of products in real time, and ensure the security of card secrets",
            "jjzg":"Gather Chinese and overseas marketing resources, localized marketing to promote your products, increase market exposure and product sales, and enhance brand influence"
        },
        "about":{
            "jc":"The CODES FLYING digital game (hereinafter referred to as 'CodesFlying') was established in 2010 and originated in China. Currently, it has branches in Hong Kong, the United Kingdom and Southeast Asia.We are online marketplace to sell Steam, Origin,Uplay, Video games , and other platform CD-Keys, in the past years, we have gained the trust of tens of thousands of customers. All due to we offer our customers with the best price ,the professional service and fast automatical deliver system. Our greatest advantages are our friendly attitude to customers , the quickness in our business processes and our great price.At the same time, CODES FLYING integrates industry resources and enhances the popularity of game products and brand influence through multiple marketing methods.Now we are primarily a wholesaler in computer and consoles games across the world.Today CODES FLYING is recognized as a leading and trusted partner in gaming industry.In the future, we will be committed to seeking cooperation with more outstanding domestic and foreign game developers/publishers, providing one-stop marketing and digital distribution services, and bringing more high-quality works to partners.We hope that we can be a useful business-partner for those who were also dedicated in gaming,so if you are interested ,we will be happy to start mutually beneficial cooperation with you.Our specialties:- B2B & B2C e-commerce, International Trade.- Computer / console game box.- Game peripheral products.(console accessories,Art Book,etc)- Digital game codes for PC (Steam, Origin, Uplay, etc.)- Digital Xbox game codes, digital Xbox live codes. - Game prepaid card.(STEAM,PSN,Nintendo,Xbox ,etc.) " ,
            "zb":" Headquarters",
            "mc":"name",
            "dz":"address",
            "dh":"phone",
            "yx":"mailbox",
            "hw":"overseas",
            "khzc":" Customer Support",
            "kfyx":"Customer Service Email",
            "kfdh":"consumer hotline",
            "jdzh":"We will be happy to answer your account usage, product consultation, technical support and other questions.",
            "swkf":"Business development",
            "lxwm":"If you are interested in our business, welcome to contact us."
         },
         "buyers":{
            "gwc":"Shopping cart",
            "jrtj":"Today's deal",
            "xgzx":"Shopping Center",
            "ddzx":"Order center",
            "dfk":"Pending payment",
            "ygm":"Oders history",
            "cwgl":"Financial Management",
            "zhye":"Account Balance",
            "xxgl":"Information management",
            "zlbj":"Data editing",
            "aqsz":"Security Settings",
            "zjmx": "Balance Details"

        },
        "shoppingCar":{
            "zhye":"Account Balance",
            "pt":"platform",
            "dq":" region",
            "yy":"language",
            "lx":"publisher",
            "slsx":"total stock",
            "jg":"price",
            "sl":"left stock",
            "zj":"Total price",
            "gwck":"Your shopping cart is empty",
            "zwsjkcz":"No data available",
            "qgxsp":"Please tick the product",
            "sysErr": "System error",
            "yebz": "Insufficient balance, please recharge",
            "kcbz": "No quantity available in stock",
            "dykc": "Purchase quantity cannot be greater than inventory",
            "xyzdqp": "The purchase quantity cannot be less than the minimum starting quantity"

        },
        "today":{
            "jrthzq":"Today's Special Zone",
            "qsryxmc":"Please enter the game name",
            "sypt":"All platforms",
            "syyy":"All languages",
            "sydq":"All regions",
            "ss":"search for",
            "zdpf":"MOQ",
            "xzzgwc":" Add to shopping cart",
            "gm":" buy",
            "zwsj":"No data",
            "qsrysj":"Please enter the information to be searched",
            "qxzsl":"Please select the quantity",
            "thgwccg":"Add to cart successfully"


        },
        "pending":{
            "cgdd":"purchase order",
            "dfkdd":"Pending payment order",
            "ddbh":"Order number",
            "spzs":"Total number of products",
            "qqrbzf":"Please confirm and pay",
            "dgsj":"Order time",
            "je":"Amount",
            "ddfk":"waiting for payment",
            "qqrnddd":"Please confirm your order information",
            "wmy":"We are in",
            "ddsq":"Receive your order request, your order is",
            "jc":"Please check whether your order is correct and complete the payment in time. Thank you",
            "ddxq":"order details",
            "ddsj":"Order time",
            "xdsj":"order time",
            "zfdd":"Pay order",
            "nqd":"Are you sure you want to pay for the order?",
            "sfzf":"Do you want to pay for the order?",
            "qx":"ancel",
            "tjdd":"Submit Order",
            "zfcg":"payment successful",
            "ygm":"Purchased order",
            "qsrddbh":"Please enter the order number",
            "qqrbxz":"Please confirm and download in time!",
            "xz":"Download",
            "wjyxz":"File has been downloaded",
            "qsrspmc":"Please enter the product name",
            "ci":"times",
            "spm":"Product name",
            "jhm":"Activation code",
            "lqsj":"Collection time",
            "spmx":"Commodity details",
            "gxgm":"Thank you for purchasing our products. This is your product list, please download",
            "yl":"Preview PDF content",
            "nian":"year",
            "yue":"month",
            "ri":"day",
            "ksrq":"Start date",
            "jsrq":"End date",
            "qxdd":"cancel order",
            "fhdd":"Back to list",
            "pfj":"Wholesale price",
            "qux":"Cancel",
            "fxs":"Publisher",
            "sl":"quantity"
        },
        "account":{
            "hbdw":"Currency Unit",
            "ckmx":"View details",
            "zhcz":"Account recharge",
            "czqlx":"Please contact for recharge",
            "wx":"Wechat",
            "cwmx":"Financial details",
            "jylx":"Transaction Type",
            "cz":"Stored value",
            "gw":"shopping",
            "jyrq":"transaction date",
            "czuo":"operating",
            "ck":"View"
        },
        "dataEdit":{
            "sjbj":"Data editing",
            "xydm":"Company registration number",
            "qsrxydm":"Please enter the company registration number",
            "qsrzq":"Please enter the correct company registration number",
            "bjcg":"Edit successfully"

        }, "setting":{
            "xgmm":"change Password",
            "ymm":"old password",
            "qsrymm":"Please enter the original password",
            "xmm":"New Password",
            "qsrxmm":"Please enter a new password",
            "qdl": 'Back to login',
            "zcmm":"Confirm password again",
            "qsrzxmm":"Please confirm the password again",
            "bdsj":"Bind phone",
            "xsj":"new phone",
            "bdyx":"Bind mailbox",
            "xyx":"new mail box",
            "ghsj":"Replace the bound mobile phone",
            "ysj":"Original phone number",
            "ghyx":"Change the bound mailbox",
            "yyx":"Original mailbox",
            "xgcg":"Successfully modified",
            "bdcg":"The mobile phone number is successfully bound~",
            "bdyxcg":"Bind the mailbox successfully~"



        },
        "pdf": {
            "download": 'Confirm Download PDF',
            "back": "Return to previous page"
        }
        
    }
    export default en_us